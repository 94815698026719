import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'popover']

  buttonTarget!: HTMLElement
  popoverTarget!: HTMLElement

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  toggle() {
    if (this.popoverTarget.classList.contains('hidden')) {
      this.popoverTarget.classList.remove('hidden')
    } else {
      this.popoverTarget.classList.add('hidden')
    }
  }

  handleClickOutside(event: MouseEvent) {
    if (!this.element.contains(event.target as Node)) {
      this.popoverTarget.classList.add('hidden')
    }
  }
}
