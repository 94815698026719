import { ActionEvent, Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'popover',
    'imageSizePopover',
    'instructionsCheckbox',
    'flaggedStepsCheckbox',
  ]

  popoverTarget!: HTMLElement
  imageSizePopoverTarget!: HTMLElement

  connect() {
    this.hideOnOutsideClick = this.hideOnOutsideClick.bind(this)
    document.addEventListener('click', this.hideOnOutsideClick)
  }

  disconnect() {
    document.removeEventListener('click', this.hideOnOutsideClick)
  }

  togglePopover(event: Event) {
    event.stopPropagation()
    this.popoverTarget.classList.toggle('hidden')
  }

  toggleImageSizePopover(event: Event) {
    event.stopPropagation()
    this.imageSizePopoverTarget.classList.toggle('hidden')
  }

  hideOnOutsideClick(event: MouseEvent) {
    if (!this.element.contains(event.target as Node)) {
      this.popoverTarget.classList.add('hidden')
      this.imageSizePopoverTarget.classList.add('hidden')
    }
  }

  updateParams(event: ActionEvent) {
    const size = event.params['size']
    if (size) {
      const params = new URLSearchParams(window.location.search)
      params.set('image_size', size)
      window.location.search = params.toString()
    }
  }

  updateInstructionsCheckbox(event: Event) {
    const target = event.target as HTMLInputElement
    const params = new URLSearchParams(window.location.search)
    params.set('show_instructions', String(target.checked))
    window.location.search = params.toString()
  }

  updateFlaggedStepsCheckbox(event: Event) {
    const target = event.target as HTMLInputElement
    const params = new URLSearchParams(window.location.search)
    params.set('show_flagged_steps', String(target.checked))
    window.location.search = params.toString()
  }
}
