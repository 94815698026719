// app/javascript/controllers/kebab_controller.ts

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu']

  menuTarget!: HTMLElement

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  toggle(event: Event) {
    event.stopPropagation()
    this.menuTarget.classList.toggle('hidden')
  }

  handleClickOutside(event: Event) {
    if (!this.element.contains(event.target as Node)) {
      this.menuTarget.classList.add('hidden')
    }
  }
}
