/* global require */
// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import '@hotwired/turbo-rails'
import '../channels'
import '../stylesheets/application.css'

import $ from 'jquery'
import 'jquery/src/jquery'
window.$ = window.jQuery = $

// Stimulus
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()
application.debug = false // Configure Stimulus development experience
const context = require.context('../controllers', true, /\.(js|ts)$/)
application.load(definitionsFromContext(context))
window.Stimulus = application
